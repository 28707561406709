.img--wrap {
  display: flex;
  gap: 40px;
  justify-content: center;
}

.img--thumb {
  height: 450px;
  width: 350px;
}

.img {
  display: block;
  height: 100%;
  width: 100%;

  object-fit: cover;
}
