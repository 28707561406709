@import '~node_modules/modern-normalize/modern-normalize.css';

html {
  scroll-behavior: smooth;
}

body {
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.02em;
  background-color: $colorBgLight;
  color: $colorTextPrimary;
}

.is-hidden {
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
}
