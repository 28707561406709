.details {
  text-align: left;

  &::before {
    content: '';
    display: block;
    width: 42px;
    height: 8px;
    background-color: $colorAccent;
    margin-bottom: 32px;
  }

  &--columns {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    gap: 32px;
    text-align: left;

    @include tablet {
      flex-direction: row;
      align-items: flex-start;
    }
  }

  &--list {
    display: flex;
    flex-direction: column;
    gap: 32px;
  }

  &--item {
    display: flex;
    align-items: center;
    gap: 16px;
  }

  &--icon {
    width: 24px;
    height: 24px;

    @include tablet {
      width: 36px;
      height: 36px;
    }
  }

  &--subtitle {
    text-transform: capitalize;
    font-weight: 500;
    font-size: 14px;
    line-height: calc(20 / 14);
  }

  &--text {
    width: 280px;
    font-size: 14px;
    line-height: calc(20 / 14);

    @media screen and (min-width: 400px) {
      width: 320px;
    }

    @include mobile {
      width: 400px;
    }

    @include tablet {
      max-width: 270px;
    }

    @include desktop {
      max-width: 480px;
    }
  }
}
