.section {
  padding-top: 70px;
  padding-bottom: 70px;

  text-align: center;

  @include tablet {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  &-beige {
    background-color: $colorBgBeige;
  }
}
