*, :before, :after {
  box-sizing: border-box;
}

html {
  tab-size: 4;
  -webkit-text-size-adjust: 100%;
  line-height: 1.15;
}

body {
  margin: 0;
  font-family: system-ui, AppleSystem, BlinkMacSystemFont, Segoe UI, Roboto, Noto Sans, Ubuntu, Cantarell, Helvetica Neue, AppleSystem, BlinkMacSystemFont, Segoe UI, Roboto, Noto Sans, Ubuntu, Cantarell, Helvetica Neue, -apple-system, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji;
}

hr {
  height: 0;
  color: inherit;
}

abbr[title] {
  text-decoration: underline dotted;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-family: ui-monospace, SFMono-Regular, Consolas, Liberation Mono, Menlo, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
}

button, input, optgroup, select, textarea {
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
}

::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

:-moz-focusring {
  outline: 1px dotted ButtonText;
}

:-moz-ui-invalid {
  box-shadow: none;
}

legend {
  padding: 0;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button, ::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

.no-scroll {
  overflow: hidden;
}

.visually-hidden {
  white-space: nowrap;
  width: 1px;
  height: 1px;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  border: 0;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

html {
  scroll-behavior: smooth;
}

body {
  letter-spacing: .02em;
  color: #52503b;
  background-color: #fff;
  font-family: Montserrat, sans-serif;
  font-size: 16px;
  font-weight: 400;
}

.is-hidden {
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
}

html {
  box-sizing: border-box;
}

*, :before, :after {
  box-sizing: inherit;
}

p, h1, h2, h3, h4, h5, h6 {
  margin: 0;
}

a {
  text-decoration: none;
}

ul, ol {
  margin: 0;
  padding: 0;
  list-style: none;
}

button {
  cursor: pointer;
}

address {
  font-style: normal;
}

img {
  max-width: 100%;
  height: auto;
  display: block;
}

th, td {
  padding: 0;
}

.container {
  max-width: 100%;
  margin: 0 auto;
  padding-left: 15px;
  padding-right: 15px;
}

@media screen and (min-width: 480px) {
  .container {
    max-width: 480px;
  }
}

@media screen and (min-width: 768px) {
  .container {
    max-width: 768px;
    padding-left: 50px;
    padding-right: 50px;
  }
}

@media screen and (min-width: 1200px) {
  .container {
    max-width: 1200px;
    padding-left: 100px;
    padding-right: 100px;
  }
}

.img--wrap {
  justify-content: center;
  gap: 40px;
  display: flex;
}

.img--thumb {
  height: 450px;
  width: 350px;
}

.img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  display: block;
}

.logo--icon {
  fill: #e08631;
  transition: fill .25s cubic-bezier(.4, 0, .2, 1);
}

.logo--icon:hover, .logo--icon:focus {
  fill: #fff;
}

.section {
  text-align: center;
  padding-top: 70px;
  padding-bottom: 70px;
}

@media screen and (min-width: 768px) {
  .section {
    padding-top: 100px;
    padding-bottom: 100px;
  }
}

.section-beige {
  background-color: #faf8f0;
}

.button {
  height: 40px;
  color: #52503b;
  background-color: #fff;
  border: 1px solid #333;
  border-radius: 6px;
  justify-content: center;
  align-items: center;
  padding: 4px 8px;
  font-size: 14px;
  display: flex;
}

.button:hover, .button:focus {
  color: #52503b;
  background-color: #e08631;
}

.btn-light {
  color: #52503b;
  background-color: #fff;
  border: 1px solid #333;
}

.btn-dark {
  color: #fff;
  background-color: #52503b;
  border: 1px solid #52503b;
}

.contact--btn {
  padding: 10px 20px;
}

.section--description-centre {
  max-width: 554px;
  margin: 0 auto 36px;
  line-height: 1.5;
}

.section--description-left {
  max-width: 900px;
  text-align: left;
  margin-bottom: 36px;
  line-height: 1.5;
}

.section--title {
  text-align: center;
  margin-bottom: 24px;
  font-size: 32px;
  font-weight: 400;
  line-height: 1.125;
}

.animated.fadeInRight {
  animation-duration: 2s;
  animation-delay: 1s;
}

.animated.fadeIn {
  animation-duration: 3s;
}

.animated.fadeInUp {
  animation-duration: 2s;
  animation-delay: 1.5s;
}

.animated.zoomIn {
  animation-duration: 2s;
  animation-delay: 2s;
}

.animated.pulse {
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-delay: 2s;
}

.header {
  width: 100%;
  height: 80px;
  background-color: #52503b;
  padding-top: 20px;
  padding-bottom: 20px;
}

.header--container {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

@media screen and (min-width: 768px) {
  .header--container {
    justify-content: space-between;
  }
}

.header--contact__wrap {
  gap: 24px;
  display: flex;
}

.header--contact {
  align-items: center;
  gap: 30px;
  display: flex;
}

.header--contact-link {
  color: #fff;
  transition: color .25s cubic-bezier(.4, 0, .2, 1);
}

@media screen and (min-width: 768px) {
  .header--contact-link {
    font-size: 14px;
  }
}

.header--contact-link:hover, .header--contact-link:focus {
  color: #e08631;
}

.header--contact-icon {
  fill: currentColor;
}

.hero {
  max-width: 4206px;
  height: 600px;
  text-align: center;
  background-color: #52503b;
  background-image: linear-gradient(to right, rgba(47, 48, 58, .4), rgba(47, 48, 58, .4)), url("foggy-carpathian-mountains.37608ab7.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin: 0 auto;
  padding-top: 80px;
  padding-bottom: 50px;
}

@media screen and (min-width: 768px) {
  .hero {
    height: 800px;
    padding-top: 100px;
    padding-bottom: 100px;
  }
}

@media screen and (min-width: 1200px) {
  .hero {
    padding-top: 200px;
    padding-bottom: 200px;
  }
}

.hero--title {
  color: #fff;
  margin-bottom: 35px;
  font-size: 32px;
}

@media screen and (min-width: 768px) {
  .hero--title {
    font-size: 52px;
  }
}

.hero--text {
  text-transform: uppercase;
  color: #fff;
  font-size: 14px;
  line-height: 1.42857;
}

.logo--hero {
  justify-content: center;
  align-items: center;
  gap: 24px;
  margin-bottom: 42px;
  display: flex;
}

.logo--hero__icon {
  fill: #e08631;
  width: 100px;
  height: 100px;
}

.logo--hero__text {
  color: #fff;
  font-size: 36px;
  font-weight: 500;
}

.logo-text-wrap {
  flex-direction: column;
  justify-content: center;
  align-items: start;
  display: flex;
  position: relative;
}

.logo-text-wrap:before {
  content: "";
  width: 2px;
  height: 92px;
  background-color: #fff;
  margin-left: 12px;
  display: inline-block;
  position: absolute;
  left: -24px;
}

.about--wrap {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 40px;
  display: flex;
}

@media screen and (min-width: 768px) {
  .about--wrap {
    flex-direction: row;
    gap: 40px;
    display: flex;
  }
}

.about--item {
  width: 280px;
  text-align: center;
  color: #333;
  line-height: 1.42857;
}

@media screen and (min-width: 480px) {
  .about--item {
    width: 360px;
  }
}

@media screen and (min-width: 768px) {
  .about--item {
    text-align: left;
  }
}

.about--item:before {
  content: "";
  width: 42px;
  height: 8px;
  background-color: #e08631;
  margin-bottom: 32px;
  display: inline-block;
}

.about--text {
  margin-bottom: 40px;
}

.details {
  text-align: left;
}

.details:before {
  content: "";
  width: 42px;
  height: 8px;
  background-color: #e08631;
  margin-bottom: 32px;
  display: block;
}

.details--columns {
  text-align: left;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  gap: 32px;
  display: flex;
}

@media screen and (min-width: 768px) {
  .details--columns {
    flex-direction: row;
    align-items: flex-start;
  }
}

.details--list {
  flex-direction: column;
  gap: 32px;
  display: flex;
}

.details--item {
  align-items: center;
  gap: 16px;
  display: flex;
}

.details--icon {
  width: 24px;
  height: 24px;
}

@media screen and (min-width: 768px) {
  .details--icon {
    width: 36px;
    height: 36px;
  }
}

.details--subtitle {
  text-transform: capitalize;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.42857;
}

.details--text {
  width: 280px;
  font-size: 14px;
  line-height: 1.42857;
}

@media screen and (min-width: 400px) {
  .details--text {
    width: 320px;
  }
}

@media screen and (min-width: 480px) {
  .details--text {
    width: 400px;
  }
}

@media screen and (min-width: 768px) {
  .details--text {
    max-width: 270px;
  }
}

@media screen and (min-width: 1200px) {
  .details--text {
    max-width: 480px;
  }
}

.slogan--text {
  font-size: 36px;
  font-weight: 700;
}

@media screen and (min-width: 768px) {
  .slogan--text {
    font-size: 48px;
  }
}

@media screen and (min-width: 1200px) {
  .slogan--text {
    font-size: 68px;
  }
}

.slogan--text:before, .bedrooms:before {
  content: "";
  width: 42px;
  height: 8px;
  background-color: #e08631;
  margin: 0 auto 32px;
  display: block;
}

.bedrooms--list {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 36px;
  display: flex;
}

@media screen and (min-width: 768px) {
  .bedrooms--list {
    flex-direction: row;
    align-items: start;
    gap: 64px;
  }
}

.bedrooms--item {
  width: 280px;
}

@media screen and (min-width: 480px) {
  .bedrooms--item {
    width: 346px;
  }
}

.bedrooms--icon {
  width: 42px;
  height: 42px;
  margin-bottom: 12px;
}

@media screen and (min-width: 768px) {
  .bedrooms--icon {
    width: 48px;
    height: 48px;
  }
}

@media screen and (min-width: 1200px) {
  .bedrooms--icon {
    width: 64px;
    height: 64px;
  }
}

.bedrooms--subtitle {
  margin-bottom: 8px;
  font-weight: 400;
  line-height: 1.5;
}

.bedrooms--text {
  font-size: 14px;
  line-height: 1.42857;
}

.feedback:before {
  content: "";
  width: 42px;
  height: 8px;
  background-color: #e08631;
  margin: 0 auto 32px;
  display: block;
}

#reviews-container {
  margin: auto;
}

.review {
  border: 1px solid #ddd;
  margin-bottom: 10px;
  padding: 10px;
}

.review-slide {
  width: 784px;
  text-align: left;
  background: #fff;
  border: 1px solid rgba(82, 80, 59, .1);
  border-radius: 4px;
  padding: 31px;
  font-family: sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.42857;
}

.review-text {
  margin-top: 20px;
  margin-bottom: 20px;
  font-family: sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.42857;
}

.review-author {
  font-family: sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 1.33333;
}

.review-author:before {
  content: "";
  width: 3px;
  height: 20px;
  vertical-align: middle;
  background-color: #e08631;
  margin-right: 10px;
  display: inline-block;
}

.reviews-slider .slick-prev .slick-arrow button {
  visibility: hidden;
  display: none;
}

.reviews-slider .slick-next .slick-arrow button {
  display: none;
}

.slick-dots {
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-top: 30px;
  display: flex;
}

.slick-dots button {
  height: 14px;
  border: 1px solid #52503b;
  border-radius: 50%;
  font-size: 0;
}

.slick-dots li.slick-active button {
  background-color: #e08631;
  border: 1px solid #e08631;
}

.slider--item {
  opacity: .85;
  text-align: center;
  transition: all .3s;
}

:root {
  --star-size: 20px;
  --star-color: #fff;
  --star-background: #52503b;
}

.review-stars {
  --percent: calc(var(--rating) / 5 * 100%);
  font-size: var(--star-size);
  font-family: Montserrat;
  line-height: 1;
  display: inline-block;
}

.review-stars:before {
  content: "★★★★★";
  letter-spacing: 3px;
  background: linear-gradient(90deg, var(--star-background) var(--percent), var(--star-color) var(--percent) );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.slick-slider {
  min-width: 0;
}

.slick-list {
  overflow: hidden;
}

.slick-track {
  justify-content: center;
  align-items: flex-start;
  display: flex;
}

@media screen and (min-width: 768px) {
  .slick-track {
    gap: 30px;
  }
}

.slider {
  padding: 0 50px;
  position: relative;
}

.slider .slick-arrow {
  z-index: 1;
  width: 36px;
  height: 36px;
  fill: #52503b;
  border: rgba(0, 0, 0, 0);
  font-size: 0;
  position: absolute;
  top: 40%;
}

.slider .slick-arrow.slick-prev {
  background: url("arrow-left.7eec7c1a.svg") 0 0 / 100% no-repeat;
  left: 0;
}

.slider .slick-arrow.slick-next {
  background: url("arrow-right.ae1d4393.svg") 0 0 / 100% no-repeat;
  right: 0;
}

.slider .slick-dots {
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-top: 30px;
  display: flex;
}

.slider .slick-dots button {
  height: 14px;
  border: 1px solid #52503b;
  border-radius: 50%;
  font-size: 0;
}

.slider .slick-dots li.slick-active button {
  background-color: #e08631;
  border: 1px solid #e08631;
}

.slider--item {
  opacity: .85;
  text-align: center;
  transition: all .3s;
}

.img {
  border-radius: 2px;
  box-shadow: 2px 4px 7px 4px rgba(0, 0, 0, .15);
}

.contacts {
  text-align: left;
  margin-bottom: 30px;
}

.contacts:before {
  content: "";
  width: 42px;
  height: 8px;
  background-color: #e08631;
  margin-bottom: 32px;
  display: block;
}

.contacts--container {
  flex-direction: column;
  align-items: center;
  gap: 32px;
  display: flex;
}

@media screen and (min-width: 768px) {
  .contacts--container {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }
}

.contacts--list {
  text-align: left;
  flex-direction: column;
  gap: 24px;
  display: flex;
}

.contacts--item {
  align-items: center;
  gap: 16px;
  display: flex;
}

.contacts--text__wrap {
  width: 260px;
}

.contacts--title {
  margin-bottom: 6px;
  font-weight: 500;
}

.contacts--link {
  color: #333;
  font-size: 14px;
  transition: color .25s cubic-bezier(.4, 0, .2, 1);
}

.contacts--link:hover, .contacts--link:focus {
  color: #e08631;
}

.contacts--googlemap {
  width: 290px;
  height: 220px;
  border-radius: 2px;
  box-shadow: 2px 4px 7px 4px rgba(0, 0, 0, .15);
}

@media screen and (min-width: 480px) {
  .contacts--googlemap {
    width: 384px;
  }
}

@media screen and (min-width: 768px) {
  .contacts--googlemap {
    width: 535px;
    height: 412px;
    margin-top: 13px;
  }
}

@media screen and (min-width: 1200px) {
  .contacts--googlemap {
    width: 634px;
    margin-top: 8px;
  }
}

.footer {
  width: 100%;
  color: #fff;
  background-color: #52503b;
  padding: 50px 0 20px;
}

@media screen and (min-width: 768px) {
  .footer {
    padding-top: 100px;
  }
}

.footer--wrap {
  flex-direction: column;
  align-items: center;
  gap: 52px;
  margin-bottom: 100px;
  display: flex;
}

@media screen and (min-width: 1200px) {
  .footer--wrap {
    flex-direction: row;
    justify-content: space-between;
  }
}

.footer--item:not(:last-child) {
  text-align: center;
  margin-bottom: 12px;
}

@media screen and (min-width: 768px) {
  .footer--item:not(:last-child) {
    text-align: center;
  }
}

@media screen and (min-width: 1200px) {
  .footer--item:not(:last-child) {
    text-align: left;
  }
}

.footer--link {
  color: #fff;
  font-size: 14px;
  line-height: 1.42857;
  transition: color .25s cubic-bezier(.4, 0, .2, 1);
}

.footer--link:hover, .footer--link:focus {
  color: #e08631;
}

.copyright {
  text-align: center;
  font-size: 10px;
}

@media screen and (min-width: 768px) {
  .copyright {
    font-size: 12px;
  }
}

.repo-link {
  color: #fff;
  margin-left: 3px;
  text-decoration: underline;
  transition: color .25s cubic-bezier(.4, 0, .2, 1);
}

.repo-link:hover, .repo-link:focus {
  color: #e08631;
}

.service--list {
  gap: 12px;
  display: flex;
}

@media screen and (min-width: 1200px) {
  .service--list {
    display: block;
  }
}

.service--link {
  color: #fff;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  transition: color .25s cubic-bezier(.4, 0, .2, 1);
  display: flex;
}

.service--link__item {
  text-align: center;
}

.service--link__text {
  display: none;
}

@media screen and (min-width: 1200px) {
  .service--link__text {
    display: block;
  }
}

.service--link:hover, .service--link:focus {
  color: #e08631;
}

.service--icon__tripadvisor {
  fill: currentColor;
  stroke: currentColor;
}

.service--icon__booking {
  fill: currentColor;
  stroke: #52503b;
}

.section-rules {
  padding-top: 24px;
  padding-bottom: 24px;
}

.section-rules:first-of-type {
  padding-top: 40px;
}

.section-rules:last-of-type {
  padding-bottom: 60px;
}

@media screen and (min-width: 768px) {
  .section-rules {
    padding-top: 36px;
    padding-bottom: 36px;
  }
}

.section--description-rules {
  max-width: 900px;
  text-align: left;
  margin-bottom: 20px;
  line-height: 1.5;
}

.slogan-rules {
  text-align: center;
  font-size: 42px;
  font-weight: 600;
}

.slogan-rules:before {
  content: "";
  width: 42px;
  height: 8px;
  background-color: #e08631;
  margin: 0 auto 32px;
  display: block;
}

@media screen and (min-width: 768px) {
  .slogan-rules {
    font-size: 54px;
  }
}

@media screen and (min-width: 1080px) {
  .slogan-rules {
    font-size: 64px;
  }
}

.showed-block {
  cursor: pointer;
  background-color: #faf8f0;
  border: 1px solid #52503b;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  justify-content: space-between;
  align-items: flex-start;
  padding: 32px;
  transition: color .25s ease-in-out, background-color .25s ease-in-out;
  display: flex;
  position: relative;
}

.showed-block:hover, .showed-block:focus {
  color: #fff;
  background-color: #52503b;
}

.section--arrow-down {
  stroke: #e08631;
  fill: rgba(0, 0, 0, 0);
  transition: transform .25s;
  position: absolute;
  top: 30px;
  right: 30px;
}

.hidden-data {
  background-color: #faf8f0;
  border-bottom: 1px solid #52503b;
  border-left: 1px solid #52503b;
  border-right: 1px solid #52503b;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  padding: 32px;
  transition: transform .25s cubic-bezier(.4, 0, .2, 1);
  transform: translateY(-10px);
}

.rules--list {
  text-align: left;
}

.rules {
  text-align: left;
  margin-bottom: 12px;
}

.rules:before {
  content: "";
  width: 42px;
  height: 8px;
  background-color: #e08631;
  margin-bottom: 32px;
  display: block;
}

.rules--item {
  gap: 16px;
  display: flex;
}

.rules--item:before {
  content: url("checkmark2.65194fed.svg");
  display: inline-block;
}

.rules--item:not(:last-child) {
  margin-bottom: 16px;
}

body {
  -webkit-backface-visibility: hidden;
}

.animated {
  animation-duration: 1s;
  animation-fill-mode: both;
}

.animated.infinite {
  animation-iteration-count: infinite;
}

.animated.hinge {
  animation-duration: 2s;
}

.animated.bounceIn, .animated.bounceOut, .animated.flipOutX, .animated.flipOutY {
  animation-duration: .75s;
}

@-webkit-keyframes pulse {
  0% {
    -webkit-transform: scale(1);
  }

  50% {
    -webkit-transform: scale(1.1);
  }

  100% {
    -webkit-transform: scale(1);
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.1);
  }

  100% {
    transform: scale(1);
  }
}

.pulse {
  animation-name: pulse;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.fadeIn {
  animation-name: fadeIn;
}

@-webkit-keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-20px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
  }
}

@keyframes fadeInDown {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.fadeInDown {
  animation-name: fadeInDown;
}

@-webkit-keyframes fadeInRight {
  0% {
    opacity: 0;
    -webkit-transform: translateX(20px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
  }
}

@keyframes fadeInRight {
  0% {
    opacity: 0;
    transform: translateX(20px);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.fadeInRight {
  animation-name: fadeInRight;
}

@-webkit-keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(20px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
  }
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.fadeInUp {
  animation-name: fadeInUp;
}

@-webkit-keyframes zoomIn {
  0% {
    opacity: 0;
    -webkit-transform: scale3d(.3, .3, .3);
  }

  50% {
    opacity: 1;
  }
}

@keyframes zoomIn {
  0% {
    opacity: 0;
    transform: scale3d(.3, .3, .3);
  }

  50% {
    opacity: 1;
  }
}

.zoomIn {
  animation-name: zoomIn;
}

/*# sourceMappingURL=index.5e30f0e5.css.map */
