.header {
  width: 100%;
  height: 80px;
  padding-top: 20px;
  padding-bottom: 20px;

  background-color: $colorBgDark;

  &--container {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include tablet {
      justify-content: space-between;
    }
  }

  &--contact__wrap {
    display: flex;
    gap: 24px;
  }

  &--contact {
    display: flex;
    gap: 30px;
    align-items: center;
  }

  &--contact-link {
    color: $colorTextWhite;

    transition: color $transDuration $cubic;

    @include tablet {
      font-size: 14px;
    }

    &:hover,
    &:focus {
      color: $colorAccent;
    }
  }

  &--contact-icon {
    fill: currentColor;
  }
}
