.container {
  margin: 0 auto;
  max-width: 100%;
  padding-left: 15px;
  padding-right: 15px;

  @include mobile {
    max-width: 480px;
  }

  @include tablet {
    max-width: 768px;
    padding-left: 50px;
    padding-right: 50px;
  }

  @include desktop {
    max-width: 1200px;
    padding-left: 100px;
    padding-right: 100px;
  }
}
