.section-rules {
  padding-top: 24px;
  padding-bottom: 24px;

  &:first-of-type {
    padding-top: 40px;
  }

  &:last-of-type {
    padding-bottom: 60px;
  }

  @include tablet {
    padding-top: 36px;
    padding-bottom: 36px;
  }
}

.section--description-rules {
  max-width: 900px;
  line-height: calc(24 / 16);
  text-align: left;
  margin-bottom: 20px;
}

.slogan-rules {
  font-size: 42px;
  font-weight: 600;
  text-align: center;

  &::before {
    content: '';
    display: block;
    margin: 0 auto;
    width: 42px;
    height: 8px;
    background-color: $colorAccent;
    margin-bottom: 32px;
  }

  @include tablet {
    font-size: 54px;
  }
  @media screen and (min-width: 1080px) {
    font-size: 64px;
  }
}

.showed-block {
  position: relative;

  display: flex;
  justify-content: space-between;

  align-items: flex-start;

  padding: 32px;
  border: 1px solid $colorBtnDark;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;

  background-color: $colorBgBeige;

  cursor: pointer;

  transition: color $transDuration ease-in-out,
    background-color $transDuration ease-in-out;

  &:hover,
  &:focus {
    color: #fff;
    background-color: $colorBtnDark;
  }
}

.section--arrow-down {
  position: absolute;
  right: 30px;
  top: 30px;

  stroke: $colorAccent;
  fill: transparent;

  transition: transform 0.25s ease;
}

.hidden-data {
  padding: 32px;

  border-right: 1px solid $colorBtnDark;
  border-bottom: 1px solid $colorBtnDark;
  border-left: 1px solid $colorBtnDark;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;

  background-color: $colorBgBeige;

  transform: translateY(-10px);

  transition: transform $transDuration $cubic;
}

.rules--list {
  text-align: left;
}
.rules {
  text-align: left;
  margin-bottom: 12px;

  &::before {
    content: '';
    display: block;
    width: 42px;
    height: 8px;
    background-color: $colorAccent;
    margin-bottom: 32px;
  }

  &--item {
    display: flex;
    gap: 16px;

    &::before {
      content: url(../images/svg/checkmark2.svg);

      display: inline-block;
    }

    &:not(:last-child) {
      margin-bottom: 16px;
    }
  }
}
