.button {
  display: flex;
  justify-content: center;
  align-items: center;

  height: 40px;
  padding: 4px 8px;
  border-radius: 6px;

  font-size: 14px;

  border: 1px solid $colorTextSecondary;
  background-color: $colorBgLight;
  color: $colorTextPrimary;

  @include tablet {
  }

  &:hover,
  &:focus {
    background-color: $colorAccent;
    color: $colorTextPrimary;
  }
}

.btn-light {
  border: 1px solid $colorTextSecondary;
  background-color: $colorBgLight;
  color: $colorTextPrimary;
}

.btn-dark {
  border: 1px solid $colorBgDark;
  background-color: $colorBgDark;
  color: $colorTextWhite;
}

.contact--btn {
  padding: 10px 20px;
}
