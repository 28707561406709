.contacts {
  text-align: left;
  margin-bottom: 30px;

  &::before {
    content: '';
    display: block;
    width: 42px;
    height: 8px;
    background-color: $colorAccent;
    margin-bottom: 32px;
  }

  &--container {
    display: flex;
    flex-direction: column;
    gap: 32px;

    align-items: center;

    @include tablet {
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
    }
  }

  &--list {
    display: flex;
    flex-direction: column;
    text-align: left;
    gap: 24px;
  }

  &--item {
    display: flex;
    gap: 16px;
    align-items: center;
  }

  &--text__wrap {
    width: 260px;
  }

  &--title {
    font-weight: 500;
    margin-bottom: 6px;
  }

  &--link {
    color: $colorTextSecondary;
    font-size: 14px;

    transition: color $transDuration $cubic;

    &:hover,
    &:focus {
      color: $colorAccent;
    }
  }

  &--googlemap {
    width: 290px;
    height: 220px;
    box-shadow: 2px 4px 7px 4px rgba(0, 0, 0, 0.15);
    border-radius: 2px;

    @include mobile {
      width: 384px;
    }
    @include tablet {
      width: 535px;
      height: 412px;
      margin-top: 13px;
    }
    @include desktop {
      width: 634px;
      margin-top: 8px;
    }
  }
}
