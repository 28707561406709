.footer {
  padding: 50px 0px 20px 0px;
  width: 100%;
  background-color: $colorBgDark;
  color: $colorTextWhite;

  @include tablet {
    padding-top: 100px;
  }

  &--wrap {
    display: flex;
    flex-direction: column;
    gap: 52px;
    align-items: center;
    margin-bottom: 100px;

    @include desktop {
      flex-direction: row;
      justify-content: space-between;
    }
  }

  &--item:not(:last-child) {
    margin-bottom: 12px;
    text-align: center;

    @include tablet {
      text-align: center;
    }

    @include desktop {
      text-align: left;
    }
  }

  &--link {
    font-size: 14px;
    line-height: calc(20 / 14);
    color: $colorTextWhite;

    transition: color $transDuration $cubic;

    &:hover,
    &:focus {
      color: $colorAccent;
    }
  }
}

.copyright {
  text-align: center;
  font-size: 10px;

  @include tablet {
    font-size: 12px;
  }
}

.repo-link {
  margin-left: 3px;
  color: #fff;
  text-decoration: underline;

  transition: color $transDuration $cubic;

  &:hover,
  &:focus {
    color: $colorAccent;
  }
}

.service--list {
  display: flex;
  gap: 12px;

  @include desktop {
    display: block;
  }
}

.service--link {
  display: flex;
  align-items: center;
  gap: 8px;

  font-size: 14px;
  color: $colorTextWhite;

  transition: color $transDuration $cubic;

  &__item {
    text-align: center;
  }

  &__text {
    display: none;

    @include desktop {
      display: block;
    }
  }

  &:hover,
  &:focus {
    color: $colorAccent;
  }
}

.service--icon__tripadvisor {
  fill: currentColor;
  stroke: currentColor;
}

.service--icon__booking {
  fill: currentColor;
  stroke: $colorBgDark;
}
