@mixin mobile {
  @media screen and (min-width: 480px) {
    @content;
  }
}

@mixin tablet {
  @media screen and (min-width: 768px) {
    @content;
  }
}

@mixin desktop {
  @media screen and (min-width: 1200px) {
    @content;
  }
}
