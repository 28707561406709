.section--description-centre {
  max-width: 554px;
  line-height: calc(24 / 16);
  margin: 0 auto;
  margin-bottom: 36px;
}

.section--description-left {
  max-width: 900px;
  line-height: calc(24 / 16);
  text-align: left;
  margin-bottom: 36px;
}
