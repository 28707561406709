.slogan--text {
  font-size: 36px;
  font-weight: 700;

  @include tablet {
    font-size: 48px;
  }

  @include desktop {
    font-size: 68px;
  }

  &::before {
    content: '';
    display: block;
    margin: 0 auto;
    width: 42px;
    height: 8px;
    background-color: $colorAccent;
    margin-bottom: 32px;
  }
}
