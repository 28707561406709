.bedrooms {
  &::before {
    content: '';
    display: block;
    margin: 0 auto;
    width: 42px;
    height: 8px;
    margin-bottom: 32px;

    background-color: $colorAccent;
  }

  &--list {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 36px;

    @include tablet {
      flex-direction: row;
      align-items: start;
      gap: 64px;
    }
  }

  &--item {
    width: 280px;

    @include mobile {
      width: 346px;
    }
  }

  &--icon {
    width: 42px;
    height: 42px;
    margin-bottom: 12px;

    @include tablet {
      width: 48px;
      height: 48px;
    }

    @include desktop {
      width: 64px;
      height: 64px;
    }
  }

  &--subtitle {
    margin-bottom: 8px;

    font-weight: 400;
    line-height: calc(24 / 16);
  }

  &--text {
    font-size: 14px;
    line-height: calc(20 / 14);
  }
}
