.logo--icon {
  fill: $colorAccent;

  transition: fill $transDuration $cubic;

  &:hover,
  &:focus {
    fill: $colorTextWhite;
  }
}
