.hero {
  margin: 0 auto;
  padding-top: 80px;
  padding-bottom: 50px;
  max-width: 4206px;
  height: 600px;

  background-image: $gradientHero,
    url(../images/bg-image/foggy-carpathian-mountains.jpg);

  background-color: $colorBgDark;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  text-align: center;

  @include tablet {
    padding-top: 100px;
    padding-bottom: 100px;
    height: 800px;
  }

  @include desktop {
    padding-top: 200px;
    padding-bottom: 200px;
  }

  &--title {
    font-size: 32px;
    color: $colorTextWhite;
    margin-bottom: 35px;

    @include tablet {
      font-size: 52px;
    }
  }

  &--text {
    font-size: 14px;
    line-height: calc(20 / 14);
    text-transform: uppercase;
    color: $colorTextWhite;
  }
}

.logo--hero {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 24px;

  margin-bottom: 42px;

  &__icon {
    fill: $colorAccent;
    width: 100px;
    height: 100px;
  }

  &__text {
    color: $colorTextWhite;
    font-weight: 500;
    font-size: 36px;
  }
}

.logo-text-wrap {
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;

  &::before {
    position: absolute;
    left: -24px;

    content: '';
    display: inline-block;
    width: 2px;
    height: 92px;
    background-color: #fff;
    margin-left: 12px;
  }
}
