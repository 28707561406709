.feedback {
  &::before {
    content: '';
    display: block;
    margin: 0 auto;
    width: 42px;
    height: 8px;
    background-color: $colorAccent;
    margin-bottom: 32px;
  }
}

#reviews-container {
  margin: auto;
}
.review {
  margin-bottom: 10px;
  padding: 10px;
  border: 1px solid #ddd;
}

.review-slide {
  width: 784px;
  padding: 31px;

  font-family: sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: calc(20 / 14);

  text-align: left;

  border-radius: 4px;
  border: 1px solid rgba(82, 80, 59, 0.1);
  background: #fff;
}

.review-text {
  font-family: sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: calc(20 / 14);

  margin-top: 20px;
  margin-bottom: 20px;
}

.review-author {
  font-family: sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: calc(20 / 15);

  &::before {
    content: '';
    display: inline-block;
    width: 3px;
    height: 20px;
    vertical-align: middle;
    background-color: $colorAccent;
    margin-right: 10px;
  }
}

// STYLES FOR SLIDER

.reviews-slider .slick-prev .slick-arrow button {
  display: none;
  visibility: hidden;
}

.reviews-slider .slick-next .slick-arrow button {
  display: none;
}

.slick-dots {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-top: 30px;
}

.slick-dots button {
  font-size: 0;
  height: 14px;

  border-radius: 50%;
  border: 1px solid $colorTextPrimary;
}

.slick-dots li.slick-active button {
  background-color: $colorAccent;
  border: 1px solid $colorAccent;
}

.slider--item {
  transition: all 0.3s ease 0s;
  opacity: 0.85;
  text-align: center;
}

// STYLES FOR STARS

:root {
  --star-size: 20px;
  --star-color: #fff;
  --star-background: #52503b;
}

.review-stars {
  --percent: calc(var(--rating) / 5 * 100%);

  display: inline-block;
  font-size: var(--star-size);
  font-family: Montserrat; // make sure ★ appears correctly
  line-height: 1;

  &::before {
    content: '★★★★★';
    letter-spacing: 3px;
    background: linear-gradient(
      90deg,
      var(--star-background) var(--percent),
      var(--star-color) var(--percent)
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}
